* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  color: white;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
background: rgb(0, 255, 255); 
}
::-webkit-scrollbar-thumb:hover {
background: rgb(0, 185, 185); 
}
body {
  height: 100vh;
}
.material-icons {
  color: inherit;
  margin-right: 10px;
}
.body-container {
  overflow: auto;
  height: calc(100vh - 55px - 40px);
  background-color: rgb(20, 20, 20);
  padding: 0 10vw;
}
.body-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding-right: 30px;
  background-color: rgb(50, 50, 50);
  color: rgb(0, 255, 255);
  font-weight: 600;
}
.body-footer a {
  display: inline;
}

a {
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.4s ease-out;
}
a:hover, .about-header:hover {
  color: rgb(0, 255, 255);
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 0 10vw;
  height: 55px;
  align-items: center;
  background-color: rgb(50, 50, 50);
}

@media screen and (max-width: 600px) {
  nav {
    display: none;
  }
}

.nav-link {
  font-size: 20px;
  padding: 13px;
  text-decoration: none;
  color: rgb(0, 255, 255);
  font-weight: 600;
  transition: all 0.4s ease-out;
  border-bottom: 4px rgb(50, 50, 50) solid;
}
nav .active {
  border-bottom: 4px rgb(0, 255, 255) solid;
}

.nav-link:hover{
  border-bottom: 4px rgb(0, 255, 255) solid;
}