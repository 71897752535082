.about-circle-images {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 30px rgb(200, 200, 200);
}
@media screen and (max-width: 600px) {
  .about-circle-images {
    width: 30vw;
    height: 30vw;
  }
}

.page-header {
  margin-bottom: 0;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 3px solid rgb(0, 255, 255);
  animation: fade-in-left 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@media screen and (max-width: 600px) {
  .page-header {
    font-size: 1.5rem;
  }
}

.about-subtext-subtle {
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  margin-top: 5px;
}

.about-divider {
  display: block;
  font-size: 2px;
  width: 30px;
  border-bottom: 3px solid rgb(0, 255, 255);
  margin-bottom: 30px;
}

.about-header {
  cursor: pointer;
}
.about-subcategory {
  height: auto;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.about-hidden {
  height: 0px;
  overflow: hidden;
}
.lightsaber {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 30px;
}

.lightsaber-hilt {
  height: 10px;
  width: 40px;
  background-color: black;
  background: rgb(50,50,50);
  background: linear-gradient(180deg, rgb(50,50,50) 0%, rgb(255,255,255) 50%, rgb(50,50,50) 100%);
  cursor: pointer;
}

.lightsaber-blade {
  height: 10px;
  width: 300px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 10px 0 15px blue;
  transition: all 0.2s ease-in;
}

.lightsaber-blade-off {
  height: 0px;
  width: 0px;
  background-color: white;
  border-radius: 5px;
  box-shadow: none;
}
