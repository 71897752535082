.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 20px;
}
@media screen and (max-width: 600px) {
  .projects-container {
    grid-template-columns: 1fr;
  }
}
.projects-card {
  background-color: rgb(80, 80, 80);
  padding: 20px;
  border-radius: 20px;
  animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

.project-key {
  font-style: italic;
  font-weight: 500;
}

.project-logos {
  height: 30px;
  margin-right: 10px;
}